export const slugToTitle = (slug: string) => {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

export const stripHtml = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, "");

export const removeEmptyValues = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((prev, curr) => {
      prev[curr] = obj[curr];
      return prev;
    }, {});
};

export const encodeQueryString = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const toAbsoluteUrl = (baseUrl: string, path: string) => {
  const p = path.split("?")[0];
  return new URL(p, baseUrl).href;
};

export const normalize = <T>(arr: T[]): { [key: string]: T } => {
  return arr.reduce((prev, curr) => {
    prev[(curr as any).id] = curr;
    return prev;
  }, {});
};

export const denormalize = <T>(obj: { [key: string]: T }): T[] => {
  return Object.keys(obj).map((k) => obj[k]);
};

export const isProduction = () => {
  return process.env.NODE_ENV === "production";
};

// See https://bvgsoftware.com/posts/react-markdown-blog-read-time-feature
export const readTime = (content: string) => {
  const WPS = 200 / 60;

  var images = 0;
  const regex = /\w/;

  let words = content.split(" ").filter((word) => {
    if (word.includes("<img")) {
      images += 1;
    }
    return regex.test(word);
  }).length;

  var imageAdjust = images * 4;
  var imageSecs = 0;
  var imageFactor = 12;

  while (images) {
    imageSecs += imageFactor;
    if (imageFactor > 3) {
      imageFactor -= 1;
    }
    images -= 1;
  }

  const minutes = Math.ceil(((words - imageAdjust) / WPS + imageSecs) / 60);

  return minutes;
};

export const isBrowser = () => typeof window !== "undefined";

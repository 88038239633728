import Link from "next/link";
import { CImage } from "../components";
import { Blockquote } from "../components/blockquote";
import { YoutubePopupButton } from "../components/youtube-popup-button";

export const mdxComponents = {
  img: (props) => {
    return CImage({
      ...props,
      height: 500,
      width: 950,
      objectFit: "contain",
      style: { objectFit: "contain" },
    });
  },
  blockquote: Blockquote,
  YoutubePopupButton,
  Link,
  figure: (props) => {
    return (
      <figure
        style={{
          marginLeft: 0,
          marginRight: 0,
          fontSize: "12px",
          fontStyle: "italic",
        }}
        {...props}
      />
    );
  },
};

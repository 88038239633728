import Link from "next/link";
import { BlogPost } from "../lib/models";
import { CImage } from "./c-image";

export interface BlogListItemProps extends BlogPost {
  isFullWidth: boolean;
}

export const BlogListItem = ({
  categoryFull,
  slug,
  title,
  excerpt,
  image,
  authorFull,
  date,
  tags,
  minutes_read,
  isFullWidth = false,
}: BlogListItemProps) => {
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const renderCardBody = (largeTitle?: boolean) => (
    <div className="card-body d-flex flex-column h-100">
      <Link
        href={categoryFull.slug}
        className="font-size-sm mb-3 fw-semi-bold d-block"
      >
        {categoryFull.title}
      </Link>
      {largeTitle ? (
        <h1 className="mb-2 mb-lg-4">
          <Link href={slug} className="text-inherit">
            {title}
          </Link>
        </h1>
      ) : (
        <h3 className="mb-2 mb-lg-4">
          <Link href={slug} className="text-inherit">
            {title}
          </Link>
        </h3>
      )}
      <p style={{ flex: 1 }}>{excerpt}</p>
      <div className="row align-items-center no-gutters mt-4">
        <div className="col lh-1">
          <p className="font-size-xs mb-0">{formattedDate}</p>
        </div>
        <div className="col-auto">
          <p className="font-size-xs mb-0">{minutes_read} Min Read</p>
        </div>
      </div>
    </div>
  );

  const renderFullWidthItem = () => (
    <div className="card shadow-lg h-100 overflow-hidden">
      <div className="row no-gutters">
        <Link
          href={slug}
          className="col-lg-8 col-md-12 col-12 img-left-rounded d-flex card-img-full-width-item"
        >
          <div className="ratio ratio-16x9">
            <CImage
              src={image}
              alt={""}
              width={"100%"}
              height={"100%"}
              className="rounded-left card-full-width-img position-absolute"
            />
          </div>
        </Link>
        <div className="col-lg-4 col-md-12 col-12">{renderCardBody(true)}</div>
      </div>
    </div>
  );

  const renderNonFullWidthItem = () => (
    <div className="card shadow-lg h-100">
      <Link href={slug} className="card-img-top">
        <div className="ratio ratio-16x9">
          <CImage
              src={image ? image : "/uploads/logo-cloudanswers-regular.png"}
              alt={""}
              // height={220}
              className="card-img-top rounded-top position-absolute"
          />
        </div>

      </Link>
      {renderCardBody()}
    </div>
  );

  return isFullWidth ? renderFullWidthItem() : renderNonFullWidthItem();
};

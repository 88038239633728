import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Link from "next/link";
import cn from "classnames";
import cookie from "js-cookie";

import { isBrowser } from "../../lib/utils";
import { CImage } from "../c-image";

import styles from "./index.module.css";

const COOKIE_NAME = "rsmodal_seen";
const setCookie = () => isBrowser() && cookie.set(COOKIE_NAME, "true");
const getCookie = () => isBrowser() && cookie.get(COOKIE_NAME);
const hasCookie = () => isBrowser() && getCookie() === "true";

export const ReportSenderModal = () => {
  const [show, setShow] = useState(!hasCookie());

  const handleClose = () => {
    setCookie();
    setShow(false);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName={styles.modalContent}
    >
      <Modal.Body>
        <h1 className="text-center fw-bold">Report Sender for Salesforce</h1>
        <div className="row align-items-center py-5">
          <div className="col-md-4 text-center">
            <CImage
              src="/assets/images/sendreport.png"
              width={140}
              height={100}
              alt=""
              className="img-fluid"
              objectFit="contain"
            />
          </div>
          <div
            className={cn("col-md-8 text-center text-md-start", styles.content)}
          >
            <div>
              <strong>Overcome reporting limitations!</strong>
            </div>{" "}
            Send reports to any email address as XLS or CSV file in a customized
            email.
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Link href="/products/report-sender" passHref>
            <Button
              onClick={handleClose}
              className={cn("fw-bold", styles.button)}
            >
              Get it FOR FREE
            </Button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import { Modal } from "react-bootstrap";
import { ServiceRequestForm } from "./service-request-form";

interface ServiceRequestModalProps {
  serviceName: string;
  show: boolean;
  onClose: () => void;
}

export const ServiceRequestModal = ({
  serviceName,
  show,
  onClose,
}: ServiceRequestModalProps) => {
  const form = (
    <ServiceRequestForm serviceName={serviceName} onSuccess={onClose} />
  );
  return (
    <>
      {/* Because this form is generated client side, the form needs to be fully rendered in the HTML to use Netlify forms */}
      <div hidden>{form}</div>
      <Modal centered show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">
            Request <strong>{serviceName}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{form}</Modal.Body>
      </Modal>
    </>
  );
};

import Link from "next/link";
import { Service } from "../lib/models";
import { CImage } from "./c-image";
import { ReviewStars } from "./reviews-stars";

export interface ServiceListItemProps extends Service {}

export const ServiceListItem = ({
  categoryFull,
  price,
  title,
  slug,
  image,
  reviews,
  averageRating,
}: ServiceListItemProps) => {
  return (
    <div className="item h-100">
      <div className="card h-100 card-hover">
        <Link
          href={slug}
          className="card-img-top"
          style={{ height: 200, overflow: "hidden" }}
        >
          {image ? (
            <CImage
              src={image}
              width={300}
              height={250}
              alt={title}
              objectFit="cover"
              style={{ height: "100%" }}
              className="rounded-top card-img-top img-fluid"
            />
          ) : (
            <img
              src="https://unsplash.it/400/250"
              width={300}
              height={200}
              alt={title}
              style={{ objectFit: "cover", width: "100%", height: 200 }}
              className="rounded-top card-img-top img-fluid"
            />
          )}
        </Link>
        <div className="card-body">
          <h4 className="mb-2 text-truncate-line-2">
            <Link href={slug} className="text-inherit">
              {title}
            </Link>
          </h4>
          {categoryFull.title}
          <div className="mt-2">
            <ReviewStars score={averageRating} />{" "}
            <span className="text-warning"> {averageRating}</span>
            <span className="font-size-xs text-muted"> ({reviews.length})</span>
          </div>
        </div>
        <div className="card-footer">
          <div className="row justify-content-between align-items-center no-gutters">
            <div className="col-auto">
              Starting from <strong>${price}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import Link from "next/link";
import { BlogPost, BlogCategory } from "../lib/models";
import { BlogListItem } from "./blog-list-item";

export interface BlogListProps {
  title: string;
  categories?: BlogCategory[];
  posts: BlogPost[];
}

export const BlogList = ({ title, categories, posts }: BlogListProps) => {
  const isCategory =
    categories && categories.findIndex((c) => c.title === title) > -1;
  const firstPost = posts[0];
  const otherPosts = posts.slice(1);
  return (
    <>
      <div className="pt-9 pb-9">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 col-12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-bold">{title}</h1>
                <p className="lead">
                  Access useful articles, whitepapiers, how-to, guides, best
                  practices and more on this blog. Have a nice reading
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8">
        <div className="container">
          <div className="row">
            {categories && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="flush-nav">
                  <nav className="nav">
                    <Link
                      href="/blog"
                      className={
                        isCategory ? "nav-link ps-0" : "nav-link ps-0 active"
                      }
                    >
                      All
                    </Link>
                    {categories.map((c) => (
                      <Link
                        href={c.slug}
                        key={c.slug}
                        className={
                          c.title === title ? "nav-link active" : "nav-link"
                        }
                      >
                        {c.title}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            )}
            <div className="col-lg-12 mb-4">
              <BlogListItem {...firstPost} isFullWidth={true} />
            </div>
            {otherPosts.map((p, i) => (
              <div key={p.title + i} className="col-lg-4 mb-4">
                <BlogListItem {...p} isFullWidth={false} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

import Link from "next/link";
import { useState } from "react";
import { slugToTitle } from "../lib/utils";

export interface TagProps {
  tag: string;
}

export const Tag = ({ tag }: TagProps) => {
  const [hover, setHover] = useState(false);
  return (
    <Link
      href={`/tags/${tag}`}
      passHref
      style={{
        padding: "5px",
        borderRadius: "5px",
        background: "#efefef",
        opacity: hover ? 1 : 0.7,
        margin: "0 5px 5px 0",
        color: "initial",
        fontSize: "11px",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {slugToTitle(tag)}
    </Link>
  );
};

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { CASubscription } from "../../models";
import { Schedule } from "../../models/schedule";
import { fetchAllSchedules, fetchScheduleByScheduleId } from "./actions";

export const schedulesAdapter = createEntityAdapter<Schedule>();

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState: schedulesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSchedules.fulfilled, (state, action) => {
      schedulesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchScheduleByScheduleId.fulfilled, (state, action) => {
      schedulesAdapter.upsertOne(state, action.payload);
    });
  },
});

import Link from "next/link";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { selectAuthState } from "../lib/store";
import { ListGroup, Dropdown, Image } from "react-bootstrap";
import useMounted from "../hooks/useMounted";
import { navItems } from "./account-sidenav";
import cn from "classnames";

export const TopnavLogin = () => {
  const { user, isAuthenticated } = useSelector(selectAuthState);
  const hasMounted = useMounted();
  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });

  const filteredNavItems = navItems.filter(
    (item) => !item.access || user.access[item.access]
  );

  const renderAvatar = () => (
    <ListGroup
      as="ul"
      bsPrefix="navbar-nav"
      className="navbar-right-wrap ms-2 d-flex nav-top-wrap"
    >
      <Dropdown as="li" className="ms-2">
        <Dropdown.Toggle
          as="a"
          bsPrefix=" "
          className="rounded-circle"
          id="dropdownUser"
        >
          <div className="avatar avatar-md avatar-indicators avatar-online">
            <Image
              alt="avatar"
              src="/api/user/photo"
              className="rounded-circle"
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          aria-labelledby="dropdownUser"
          // className="dropdown-menu dropdown-menu-end"
          show={hasMounted && isDesktop ? true : false}
        >
          <Dropdown.Item
            className="mt-3"
            as={Link}
            passHref
            href="/account/subscriptions"
          >
            <div className="d-flex">
              <div className="avatar avatar-md avatar-indicators avatar-online">
                <Image
                  alt="avatar"
                  src="/api/user/photo"
                  className="rounded-circle"
                />
              </div>
              <div className="ms-3 lh-1">
                <h5 className="mb-1">{user?.name}</h5>
                <p className="mb-0 text-muted">{user?.email}</p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            eventKey="2"
            as={Link}
            passHref
            href="/account/subscriptions"
          >
            <>
              <i className="fe fe-user me-2"></i> Subscriptions
            </>
          </Dropdown.Item>
          <Dropdown.Item as={Link} href="/account/billing" passHref>
            <>
              <i className="fe fe-star me-2"></i> Billing
            </>
          </Dropdown.Item>
          <Dropdown.Divider />
          {filteredNavItems.length > 0 && (
            <>
              {filteredNavItems.map((item) => (
                <Dropdown.Item as={Link} href={item.href} passHref>
                  <>
                    <i className={cn(item.iconClassName, "me-2")}></i>
                    {item.title}
                  </>
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item
            className="mb-2"
            as={Link}
            href="/api/auth/signout"
            passHref
          >
            <>
              <i className="fe fe-power me-2"></i> Sign Out
            </>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ListGroup>
  );

  const renderLoginButton = () => (
    <Link href="/login" passHref className="btn btn-primary">
      Login with Salesforce
    </Link>
  );

  return isAuthenticated ? renderAvatar() : renderLoginButton();
};

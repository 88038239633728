import { ReactNode, useContext } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import Head from "next/head";
import { NextSeo, ArticleJsonLd } from "next-seo";
import { ToastContainer } from "react-toastify";

import { GlobalPropsContext } from "../pages/_app";

import { Topbanner } from "./topbanner";
import { Topnav } from "./topnav";
import { Footer } from "./footer";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const {
    pageDescription,
    pageImage,
    pageTitle,
    pageTitleSuffix,
    config,
    article,
    publishDate,
  } = useContext(GlobalPropsContext);
  const { href: currentUrl } = new URL(router.asPath, config?.base_url);
  const { href: image } = new URL(pageImage, config?.base_url);
  const { href: realLogo } = new URL(config.logo, config?.base_url);
  const domain = new URL(config?.base_url)?.hostname;

  const openGraph: any = {
    type: article ? "article" : "website",
    url: currentUrl,
    title: pageTitle,
    description: pageDescription,
    images: [
      {
        url: image,
        width: 800,
        height: 600,
      },
    ],
  };

  if (article && publishDate) {
    openGraph.article = {};
    openGraph.authors = [article.authorName];
    openGraph.article.publishedTime = new Date(publishDate);
  }

  return (
    <div>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta name="cf-2fa-verify" content="mJRAlybecw78usDxPLpJ" />
      </Head>
      <NextSeo
        title={`${pageTitle} ${pageTitleSuffix}`}
        description={pageDescription}
        canonical={currentUrl}
        openGraph={openGraph}
        twitter={{
          cardType: "summary_large_image",
          site: config.twitter_handle,
          handle: config.twitter_handle,
        }}
      />
      {article && (
        <ArticleJsonLd
          url={currentUrl}
          title={pageTitle}
          images={[image]}
          datePublished={publishDate}
          authorName={[article.authorName]}
          publisherName={config.company_name}
          publisherLogo={realLogo}
          description={pageDescription}
        />
      )}
      <main>
        <Topbanner />
        <Topnav />
        {children}
        <Footer />
        <ToastContainer />
        {/* Plausible Analytics */}
        <Script
          defer
          data-domain={domain}
          data-api="/pa/api/event"
          src="/pa/js/script.js"
        ></Script>
      </main>
    </div>
  );
};

import { Testimonial } from "../lib/models";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { CImage } from "./c-image";
import ReactStars from "react-rating-stars-component";

interface TestimonialListItemProps extends Testimonial {}

export const TestimonialListItem = ({
  app_name,
  title,
  person_name,
  person_image,
  person_job_title,
  rating,
  isProductPage,
}: TestimonialListItemProps) => {
  return (
    <div className="item">
      <div className="card border shadow-none testimonial">
        <div className="card-body p-5 pb-1 pb-2 pb-3 ">
          {/*<div className="mb-2"></div>*/}
          {!isProductPage && <h2>{app_name}</h2>}
          <div className="testimonial-body overflow-y-auto">
            {rating && (
              <ReactStars
                count={5}
                value={rating}
                size={24}
                activeColor="#ffd700"
                edit={false}
              />
            )}
            {title.length > 250 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    id={`tooltip-${title}`}
                    className="testimonial-tooltip"
                  >
                    {title}
                  </Tooltip>
                }
              >
                <p className="fs-4 text-dark fst-italic fw-medium mb-0">
                  {title}
                  {/*"{title.substring(0, 250)}..."*/}
                </p>
              </OverlayTrigger>
            ) : (
              <p className="fs-4 text-dark fst-italic fw-medium mb-0">
                "{title}"
              </p>
            )}
          </div>
        </div>
        <div className="card-footer px-5 py-4">
          <div className="d-flex align-items-center">
            {person_image && (
              <CImage
                src={person_image}
                height={40}
                width={40}
                alt={person_name}
                className="avatar avatar-md rounded-circle"
              />
            )}

            <div className="ms-3">
              <h4 className="mb-0 fw-bold">{person_name}</h4>
              {person_job_title && (
                <p className="mb-0 small">{person_job_title}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

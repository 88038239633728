import { ShareLinks } from "../lib/helpers/share-links";

interface ShareIconsProps {
  links: ShareLinks;
  hideShareText?: boolean;
}
export const ShareIcons = ({ links, hideShareText }: ShareIconsProps) => {
  return (
    <div>
      {!hideShareText && <span className="me-2">Share</span>}
      <a href={links.facebook} className="me-2 text-inherit">
        <i className="fab fa-facebook"></i>
      </a>
      <a href={links.twitter} className="me-2 text-inherit">
        <i className="fab fa-x-twitter"></i>
      </a>
      <a href={links.linkedin} className="me-2 text-inherit">
        <i className="fab fa-linkedin"></i>
      </a>
    </div>
  );
};

import { ReactNode } from "react";
import dynamic from "next/dynamic";

const Slider = dynamic(() => import("./c-slider-inner"));

export const CSlider = (props: {
  settings: any;
  children: ReactNode;
  controlsPosition?: "side" | "bottom";
}) => {
  return <Slider {...props} />;
};

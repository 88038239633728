import { MDXRemote } from "next-mdx-remote";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { mdxComponents } from "../lib/mdx";
import { FAQItem } from "../lib/models";

interface FaqAccordionProps {
  items: FAQItem[];
}

export const FaqAccordion = ({ items }: FaqAccordionProps) => {
  return (
    <Accordion defaultActiveKey={items[0]?.q || "key"} flush>
      {items.map((f) => (
        <Accordion.Item key={f.q} eventKey={f.q}>
          <Accordion.Header>
            <h3 className="mb-0">{f.q}</h3>
          </Accordion.Header>
          <Accordion.Body>
            <MDXRemote {...f.aMdx} components={mdxComponents} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

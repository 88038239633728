import { useContext } from "react";
import Link from "next/link";
import { GlobalPropsContext } from "../pages/_app";
import { CImage } from "./c-image";
import { MailchimpForm } from "./mailchimp-form";
import { getIconPrefix } from "../lib/helpers/get-icon-prefix";

export const Footer = () => {
  const { config, footernavMenu } = useContext(GlobalPropsContext);
  const {
    logo,
    company_name,
    locations,
    about,
    contact_phone,
    contact_email,
    social_links,
  } = config;

  return (
    <>
      <hr className="m-0" />
      <div className="pt-lg-16 pt-8 footer bg-white">
        <div className="container">
          <div className="row">
            {/* Left side */}
            <div className="col-lg-3 mb-4">
              <div className="mb-4" style={{ width: "200px" }}>
                <CImage
                  src={logo}
                  width={200}
                  height={30}
                  alt={`${company_name} logo`}
                  className="img-fluid"
                  objectFit="contain"
                />
                <div className="mt-4">
                  <p>{about}</p>

                  <div className="fs-4 mt-4">
                    {social_links.map((l) => (
                      <a
                        key={l.url}
                        href={l.url}
                        className={`${getIconPrefix(l.icon)} ${
                          l.icon
                        } text-muted me-2`}
                      ></a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Newsletter */}
            {/* <div className="col-lg-3 mb-4">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Newsletter</h3>
                <MailchimpForm />
              </div>
            </div> */}

            {/* Partners */}
            <div className="col-lg-3 mb-4">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Partners</h3>
                <img
                  className="partner-img"
                  src="/uploads/salesforce-partner.png"
                  alt="Salesforce Partner"
                />
              </div>
            </div>

            {/* Menu items */}
            <div className="col-lg-3 mb-4">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Company</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  {footernavMenu.map((m) => (
                    <li key={m.slug}>
                      <Link href={m.slug} passHref className="nav-link">
                        {m.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Contact details */}
            <div className="col-lg-3 mb-4">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Get in touch</h3>
                <p>Locations: {locations}</p>
                <p className="mb-1">
                  Email:{" "}
                  <a
                    className="text-dark fw-semi-bold"
                    href={`mailto:${contact_email}`}
                  >
                    {contact_email}
                  </a>
                </p>
                <p>
                  Phone:{" "}
                  <a
                    href={`tel:${contact_phone}`}
                    className="text-dark fw-semi-bold"
                  >
                    {contact_phone}
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/* Copyright footer */}
          <div className="row align-items-center no-gutters border-top py-2 mt-6">
            <div className="col-lg-4 col-md-5 col-12">
              <span>
                © {new Date().getFullYear()} CloudAnswers. All Rights Reserved.
              </span>
            </div>

            <div className="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
              <nav className="nav nav-footer">
                <Link passHref href="/privacy-policy" className="nav-link ps-0">
                  Privacy Policy
                </Link>
                <Link
                  passHref
                  href="/subscription-agreement"
                  className="nav-link"
                >
                  Terms of Use
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

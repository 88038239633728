import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import * as authApi from "../../api/auth";
import { AccountUpdateRequest, SignupRequest } from "../../models";

export const fetchCurrentUser = createAsyncThunk(
  "auth/FETCH_CURRENT_USER",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authApi.fetchCurrentUser();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/SIGNUP",
  async (req: SignupRequest, { rejectWithValue }) => {
    try {
      const response = await authApi.signup(req);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "auth/UPDATE_ACCOUNT",
  async (req: AccountUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await authApi.updateAccount(req);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toogleSignUpModal = createAction<boolean>(
  "auth/OPEN_SIGNUP_MODAL"
);

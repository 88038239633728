import { useSelector } from "react-redux";
import { selectAuthState } from "../lib/store";

export interface WithCheckAccessProps {
  accessField?: string;
  children: React.ReactNode;
}

export const WithCheckAccess = ({
  accessField,
  children,
}: WithCheckAccessProps) => {
  const { user } = useSelector(selectAuthState);
  if (!accessField || user.access[accessField]) {
    return <>{children}</>;
  }
  return <>Forbidden</>;
};

import { useState } from "react";
import { Form } from "react-bootstrap";
import { submitIntercomForm } from "../lib/helpers/intercom-form";
import { LoadingButton } from "./loading-button";
import { SnackbarLazy } from "./snackbar-lazy";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  topic: string;
  subject: string;
  message: string;
}

const defaultValues: FormValues = {
  name: "",
  email: "",
  phone: "",
  topic: "",
  subject: "",
  message: "",
};

export const ContactForm = (products) => {
  const [isLoading, setIsLoading] = useState(false);
  const [v, setV] = useState<FormValues>(defaultValues);
  const [resultMessage, setResultMessage] = useState("");

  const handleChange = (e) => {
    setV((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleChangeTopic = (e) => {
    setV((v) => ({ ...v, topic: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formName = form.getAttribute("name");
    try {
      setIsLoading(true);
      await submitIntercomForm(formName, v);
      setResultMessage("Form submitted successfully!");
      setV(defaultValues);
    } catch (err) {
      console.error("Form submission error:", err);
      setResultMessage("Form submission failed!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form name="contact" onSubmit={handleSubmit}>
        <input type="hidden" onChange={handleChange} />
        <Form.Group className="mb-4">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={v.name}
            onChange={handleChange}
            placeholder="Enter name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Email address*</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={v.email}
            onChange={handleChange}
            placeholder="Enter email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            type="number"
            name="phone"
            value={v.phone}
            onChange={handleChange}
            placeholder="Enter phone number"
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Topic*</Form.Label>
          <Form.Select aria-label="Enter topic" onChange={handleChangeTopic}>
            <option value="" disabled selected hidden>
              Enter topic
            </option>
            {products.products.map((p, i) => (
              <option key={p.product_key} value={p.product_key}>
                {p.title}
              </option>
            ))}
            <option key="consulting" value="consulting">
              Consulting
            </option>
            <option key="partnerships" value="partnerships">
              Partnerships
            </option>
            <option key="other" value="other">
              Other
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Subject*</Form.Label>
          <Form.Control
            type="text"
            name="subject"
            value={v.subject}
            onChange={handleChange}
            placeholder="Enter subject"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Message*</Form.Label>
          <Form.Control
            type="text"
            name="message"
            value={v.message}
            placeholder="Enter message"
            onChange={handleChange}
            as="textarea"
            rows={5}
            required
          />
        </Form.Group>
        <div className="d-flex flex-column">
          <LoadingButton type="submit" isLoading={isLoading} />
        </div>
      </Form>
      {resultMessage && (
        <SnackbarLazy
          className="mt-1"
          message={resultMessage}
          timeoutMs={4000}
        />
      )}
    </>
  );
};

import { ReactNode, useState } from "react";
import YoutubeVideoModal from "./common/modals/YoutubeVideoModal";

export interface YoutubePopupButtonProps {
  youtubeId: string;
  className?: string;
  children: ReactNode;
}

export const YoutubePopupButton = ({
  youtubeId,
  className = "",
  children,
}: YoutubePopupButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button onClick={() => setIsOpen(true)} className={className}>
        {children}
      </button>
      <YoutubeVideoModal
        youtubeId={youtubeId}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      />
    </>
  );
};

import { useState } from "react";

import { Review } from "../lib/models";
import { ReviewListItem } from "./review-list-item";

interface ReviewListProps {
  reviews: Review[];
}

export const ReviewList = ({ reviews }: ReviewListProps) => {
  const [searchValue, setSearchValue] = useState("");
  const items = reviews.filter((r) =>
    r.content.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <div className="d-lg-flex align-items-center justify-content-between mb-5">
        <div className="mb-3 mb-lg-0">
          <h3 className="mb-0">Reviews</h3>
        </div>
        <div>
          <form className="form-inline">
            <div className="d-flex align-items-center me-2">
              <span className="position-absolute ps-3">
                <i className="fe fe-search"></i>
              </span>
              <input
                type="search"
                className="form-control ps-6"
                placeholder="Search Review"
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      {items.map((r, i) => (
        <ReviewListItem key={r.name + i} {...r} />
      ))}
      {reviews.length == 0 && <h4 className="text-center">No reviews yet!</h4>}
    </>
  );
};

import { Button, ButtonProps, Spinner } from "react-bootstrap";

export interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText?: string;
  nonLoadingText?: string;
}

export const LoadingButton = ({
  isLoading,
  loadingText,
  nonLoadingText,
  className,
  ...rest
}: LoadingButtonProps) => {
  const _loadingText = loadingText || "Submitting...";
  const _nonLoadingText = nonLoadingText || "Submit";

  return (
    <Button
      {...rest}
      className={`d-flex justify-content-center align-items-center ${className}`}
    >
      {isLoading && <Spinner size="sm" animation="border" className="me-2" />}
      {isLoading ? _loadingText : _nonLoadingText}
    </Button>
  );
};

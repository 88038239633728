import { useRouter } from "next/router";
import { Provider, useSelector } from "react-redux";
import { createContext, useEffect } from "react";

import {
  Layout,
  AccountLayout,
  PurchaseSuccessModal,
  SignupModal,
} from "../components";
import { WithCheckAccess } from "../components/with-check-access";
import { GlobalStaticProps } from "../lib/helpers/get-static-props";
import {
  store,
  useAppDispatch,
  fetchCurrentUser,
  selectAuthState,
} from "../lib/store";
import "../styles/theme.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Script from "next/script";
import Head from "next/head";

const defaultStaticProps: GlobalStaticProps = {
  topnavMenu: [],
};

export const GlobalPropsContext =
  createContext<GlobalStaticProps>(defaultStaticProps);

function Child({ children }) {
  const authDispatch = useAppDispatch();
  const { openSignUpModal } = useSelector(selectAuthState);

  useEffect(() => {
    authDispatch(fetchCurrentUser());
  }, []);

  return (
    <div>
      {children}
      {openSignUpModal && <SignupModal />}
    </div>
  );
}

function MyApp({ Component, pageProps }) {
  const global: GlobalStaticProps = pageProps.global ?? {};
  const router = useRouter();
  const { purchaseSuccess } = router.query;
  const renderLayout = () => (
    <Layout>
      {router.asPath.includes("/account/") ? (
        <AccountLayout>
          <WithCheckAccess accessField={Component.accessField}>
            <Component {...pageProps} />
          </WithCheckAccess>
        </AccountLayout>
      ) : (
        <Component {...pageProps} />
      )}
    </Layout>
  );

  return (
    <Provider store={store}>
      {/* Init Intercom */}
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            window.intercomSettings = {
              app_id: "g8vi6v4i",
            };
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/g8vi6v4i';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `,
        }}
      />
      <Head>
        {/* Import Inter font */}
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Child>
        <GlobalPropsContext.Provider value={global || defaultStaticProps}>
          {global.disableLayout ? <Component {...pageProps} /> : renderLayout()}
        </GlobalPropsContext.Provider>
      </Child>
      <PurchaseSuccessModal
        show={Boolean(purchaseSuccess)}
        title="Thank you"
        body="You have successfully subscribed to our product!"
        onClose={() =>
          router.replace(router.asPath.split("?")[0], undefined, {
            shallow: true,
          })
        }
      />
    </Provider>
  );
}

export default MyApp;

import { Spinner } from "react-bootstrap";

export interface LoaderProps {
  containerMinHeight?: string;
}

export const Loader = ({ containerMinHeight }: LoaderProps) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: containerMinHeight || "100px",
    }}
  >
    <Spinner animation="border" />
  </div>
);

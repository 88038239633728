import { ChangeEventHandler, FC, FormEventHandler, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { SignupRequest } from "../../lib/models";
import {
  selectAuthState,
  selectLoading,
  signup,
  toogleSignUpModal,
  useAppDispatch,
} from "../../lib/store";
import { LoadingButton } from "../loading-button";

interface FormValues extends SignupRequest {}

export interface SignupModalProps {}

export const SignupModal: FC<SignupModalProps> = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector(selectAuthState);
  const isLoading = useSelector(selectLoading([signup]));
  const [values, setValues] = useState<FormValues>({
    company_name: "",
    billing_email: user.email,
  });

  const handleChange =
    (key: keyof FormValues): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setValues({ ...values, [key]: e.target.value });
    };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await toast.promise(dispatch(signup(values)), {
      pending: "Saving...",
      success: "Billing account has been created!",
      error: "Something went wrong!",
    });
    handleCancel();
  };

  const handleCancel = () => {
    dispatch(toogleSignUpModal(false));
  };

  return (
    <Modal centered show onHide={handleCancel}>
      <Modal.Header>
        <Modal.Title>Complete your account</Modal.Title>
      </Modal.Header>
      <Form name="signup" onSubmit={handleSubmit}>
        <Modal.Body>
          <p>
            We need a bit more information from you to complete your account.
          </p>
          <Form.Group className="mb-4">
            <Form.Label>Company name*</Form.Label>
            <Form.Control
              type="text"
              name="company_name"
              value={values.company_name}
              onChange={handleChange("company_name")}
              required
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Billing email*</Form.Label>
            <Form.Control
              type="email"
              name="billing_email"
              value={values.billing_email}
              onChange={handleChange("billing_email")}
              required
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="text" onClick={handleCancel} size="sm">
            Cancel
          </Button>
          <LoadingButton
            isLoading={isLoading}
            variant="primary"
            type="submit"
            size="sm"
          >
            Submit
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

import { useState } from "react";
import { Form } from "react-bootstrap";

import { submitIntercomForm } from "../lib/helpers/intercom-form";
import { LoadingButton } from "./loading-button";
import { SnackbarLazy } from "./snackbar-lazy";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  service: string;
  message: string;
}

const defaultValues: FormValues = {
  name: "",
  email: "",
  phone: "",
  service: "",
  message: "",
};

interface ServiceRequestFormProps {
  onSuccess: () => void;
  serviceName: string;
}

export const ServiceRequestForm = ({
  onSuccess = () => {},
  serviceName,
}: ServiceRequestFormProps) => {
  const [resultMessage, setResultMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [v, setV] = useState<FormValues>({
    ...defaultValues,
    service: serviceName,
  });

  const handleChange = (e) => {
    setV((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formName = form.getAttribute("name");
    try {
      setIsLoading(true);
      await submitIntercomForm(formName, v);
      setResultMessage("Form submitted sucessfully!");
      setV(defaultValues);
      onSuccess();
    } catch (err) {
      setResultMessage("Form submission failed!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form name="service_requests" onSubmit={handleSubmit}>
        <input type="hidden" name="fullname" onChange={handleChange} />

        <Form.Group className="mb-4">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={v.name}
            onChange={handleChange}
            placeholder="Enter name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Email address*</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={v.email}
            onChange={handleChange}
            placeholder="Enter email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            value={v.phone}
            onChange={handleChange}
            placeholder="Enter phone number"
          />
        </Form.Group>
        <Form.Group hidden>
          <Form.Label>Service</Form.Label>
          <Form.Control
            type="hidden"
            name="service"
            value={v.service}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Message</Form.Label>
          <Form.Control
            type="text"
            name="message"
            value={v.message}
            placeholder="Enter message"
            onChange={handleChange}
            as="textarea"
            rows={5}
          />
        </Form.Group>
        <div className="d-flex flex-column">
          <LoadingButton type="submit" isLoading={isLoading} />
        </div>
      </Form>
      {resultMessage && (
        <SnackbarLazy message={resultMessage} timeoutMs={4000} />
      )}
    </>
  );
};

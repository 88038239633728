import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/schedules";
import { Schedule } from "../../models/schedule";

const schedulesJsonList: Schedule[] = [
  {
    id: "abc",
    name: "Weekly Run",
    description: "Run this backup on weekly basis",
    type: "cron",
    cronsyntax: "0 0 * * *",
  },
];

export const fetchAllSchedules = createAsyncThunk(
  "schedules/FETCH_ALL",
  async (_, { rejectWithValue }) => {
    try {
      // todo
      // const response = await api.fetchAll();
      const response = schedulesJsonList;
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchScheduleByScheduleId = createAsyncThunk(
  "schedules/FETCH_BY_SCHEDULE_ID",
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      // todo
      // const response = await api.fetchByScheduleId(scheduleId);
      const response = schedulesJsonList.find(
        (schedule) => schedule.id === scheduleId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import Image, { ImageProps } from "next/image";

type CImageProps = Omit<ImageProps, "width" | "height"> & {
  style?: any;
  objectFit?: string;
  width?: number | string;
  height?: number | string;
};

export const CImage = ({
  className,
  objectFit,
  width,
  height,
  ...rest
}: CImageProps) => {
  const internalClassName = `${className} img-fluid`;
  if (!rest.src) {
    return null;
  }

  // If it's an external link or a .gif image we do not optimize it.If we don't do this
  // it returns the error '"url" parameter is valid but upstream response is invalid'
  if (
    (typeof rest.src === "string" && rest.src.startsWith("http")) ||
    (typeof rest.src === "string" && rest.src.endsWith(".gif"))
  ) {
    rest.unoptimized = true;
  }
  return (
    <div
      style={{ position: "relative", width, height }}
      className={internalClassName}
    >
      <Image
        style={{
          objectFit: objectFit ?? "cover",
        }}
        className={internalClassName}
        fill
        {...rest}
      />
    </div>
  );
};

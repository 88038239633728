import { createSlice } from "@reduxjs/toolkit";
import { AuthUser } from "../../models";
import {
  fetchCurrentUser,
  signup,
  updateAccount,
  toogleSignUpModal,
} from "./actions";

const initialState = {
  isAuthenticated: false,
  user: { access: {} } as AuthUser,
  initialized: false,
  openSignUpModal: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.initialized = true;
      state.isAuthenticated = true;
      state.user = action.payload;
    });
    builder.addCase(fetchCurrentUser.rejected, (state, action) => {
      state.initialized = true;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(toogleSignUpModal, (state, action) => {
      state.openSignUpModal = action.payload;
    });
  },
});

import { Review } from "../lib/models";
import {
  getAggregatedReviewCounts,
  getAverageReviewScore,
} from "../lib/helpers/reviews";
import { ReviewStars } from "./reviews-stars";

interface ReviewsSummaryProps {
  reviews: Review[];
}

export const ReviewsSummary = ({ reviews }: ReviewsSummaryProps) => {
  const counts = getAggregatedReviewCounts(reviews);
  const average = getAverageReviewScore(reviews);
  const averageScore = average.toFixed(1);
  const reversedKeys = Object.keys(counts).reverse();
  const getPercentage = (key: string) =>
    (counts[key] / reviews.length) * 100 || 0;

  return (
    <div className="row align-items-center justify-content-between">
      <div className="text-center">
        <h3 className="display-2 fw-bold">{averageScore}</h3>
        <ReviewStars score={average} />
        <p className="mb-0 font-size-xs">(Based on {reviews.length} reviews)</p>
      </div>
      <div className="pt-3 order-3 d-none d-lg-block px-4" style={{ flex: 1 }}>
        {reversedKeys.map((key) => {
          const width = getPercentage(key);
          return (
            <div key={key} className="progress mb-3" style={{ height: "6px" }}>
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                style={{ width: `${width}%` }}
                aria-valuenow={width}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="order-md-3">
        {reversedKeys.map((key, index) => {
          const percentage = parseInt(getPercentage(key).toFixed(0));
          return (
            <div key={key + index}>
              <ReviewStars score={6 - index} />
              <span className="ms-1">{percentage}%</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { Review } from "../lib/models";
import { CImage } from "./c-image";
import { ReviewStars } from "./reviews-stars";

interface ReviewListItemProps extends Review {}

export const ReviewListItem = ({
  name,
  rating,
  image,
  content,
}: ReviewListItemProps) => {
  return (
    <div className="media border-bottom pb-4 mb-4">
      <CImage
        src={image}
        width={56}
        height={56}
        alt={name}
        className="rounded-circle avatar-lg"
      />
      <div className="media-body ms-3">
        <h4 className="mb-1">{name}</h4>
        <div className="font-size-xs mb-2">
          <ReviewStars score={rating} />
        </div>
        <p>{content}</p>
      </div>
    </div>
  );
};

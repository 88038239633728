import { Col, Row } from "react-bootstrap";

export const Topbanner = () => {
  return (
    <div className="text-white bg-dark py-2">
      <div className="container-fluid justify-content-center">
        <Row className="text-center">
          <Col md={12}>
            Support Ukraine 🇺🇦{" "}
            <a
              style={{ color: "#78c5ff" }}
              href="https://war.ukraine.ua/support-ukraine/"
            >
              Help Provide Humanitarian Aid to Ukraine.
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

import Link from "next/link";
import { Product } from "../lib/models";
import { CImage } from "./c-image";

export interface ProductListItemProps extends Product {}

export const ProductListItem = ({
  title,
  slug,
  image,
  excerpt,
}: ProductListItemProps) => {
  return (
    <Link href={slug} passHref className="item h-100">
      <div className="card h-100 card-hover">
        <div className="card-img-top">
          <div className="ratio ratio-4x3">
            <CImage
              src={image}
              height={"100%"}
              alt={title}
              objectFit="cover"
              className="rounded-top card-img-top position-absolute"
            />
          </div>
        </div>
        <div className="card-body">
          <h4 className="mb-2 text-truncate-line-2">{title}</h4>
          <p className="mb-0 text-inherit">{excerpt}</p>
        </div>
      </div>
    </Link>
  );
};

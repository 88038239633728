import { Person } from "../lib/models";
import { CImage } from "./c-image";

interface PersonListItem extends Person {}

export const PersonListItem = ({ title, job_title, image }: PersonListItem) => {
  return (
    <div className="card card-hover h-100">
      <div className="card-img-top">
        <div className="ratio ratio-4x3">
          <CImage
            src={image}
            height={"100%"}
            alt={title}
            objectFit="cover"
            className="rounded-top position-absolute"
          />
        </div>
      </div>
      <div className="card-body">
        <h3 className="mb-0 fw-semi-bold">{title}</h3>
        <p className="lh-sm mb-1">{job_title}</p>
      </div>
    </div>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { loadingReducer } from "./loading";
import { authSlice } from "./auth";
import { subscriptionsSlice } from "./subscriptions";
import { schedulesSlice } from "./schedules";
import { backupExportsSlice } from "./backup-exports";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    subscriptions: subscriptionsSlice.reducer,
    loading: loadingReducer,
    schedules: schedulesSlice.reducer,
    backupExports: backupExportsSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

import Link from "next/link";
import { useRouter } from "next/router";
import { PricingItem } from "../lib/models";

interface PricingTableProps {
  showMonthlyPrice: boolean;
  item: PricingItem;
}

export const PricingTable = ({ item, showMonthlyPrice }: PricingTableProps) => {
  const router = useRouter();

  return (
    <div className="card border-0 mb-3">
      <div className="p-5 text-center">
        <div className="mb-5">
          <h2 className="fw-bold">{item.title}</h2>
          <p className="mb-0">{item.description}</p>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Price item={item} showMonthlyPrice={showMonthlyPrice} />
        </div>
        {item.button_link.startsWith("http") ? (
          <a
            target="_blank"
            href={item.button_link}
            className={`btn ${
              item.recommended ? "btn-primary" : "btn-outline-primary"
            } btn-block`}
          >
            {item.button_text}
          </a>
        ) : (
          <Link
            href={{
              pathname: `${router.asPath}/${item.button_link}`,
              query: { period: showMonthlyPrice ? "monthly" : "yearly" },
            }}
            passHref
            className={`btn ${
              item.recommended ? "btn-primary" : "btn-outline-primary"
            } btn-block`}
          >
            {item.button_text}
          </Link>
        )}
      </div>
      <hr className="m-0" />
      <div className="p-5">
        {item.includes_title && (
          <h4 className="fw-bold mb-4">{item.includes_title}</h4>
        )}
        <ul className="list-unstyled mb-0">
          {item.includes_items.map((i) => (
            <li key={i} className="mb-1">
              <span className="text-success me-2">
                <i className="far fa-check-circle"></i>
              </span>
              <span>{i}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Price = ({ item, showMonthlyPrice }: PricingTableProps) => {
  if (item.price_custom)
    return (
      <span
        dangerouslySetInnerHTML={{ __html: item.price_custom }}
        className="h3 mb-0 fw-bold"
      />
    );
  return (
    <>
      <span className="h3 mb-0 fw-bold">
        $
        <span
          style={{ fontSize: "2pc", lineHeight: "1.1em" }}
          className="price-card--price-number toggle-price-content"
        >
          {showMonthlyPrice ? item.price_monthly : item.price_yearly}
        </span>
      </span>
      <span className="align-self-end mb-1 ms-2 toggle-price-content">
        / {showMonthlyPrice ? "Monthly" : "Yearly"}
      </span>
    </>
  );
};

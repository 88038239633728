import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { BackupExport } from "../../models/backup-export";
import {
  fetchAllBackupExports,
  fetchBackupExportByBackupExportId,
} from "./actions";

export const backupExportsAdapter = createEntityAdapter<BackupExport>();

export const backupExportsSlice = createSlice({
  name: "backupExports",
  initialState: backupExportsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBackupExports.fulfilled, (state, action) => {
      backupExportsAdapter.setAll(state, action.payload);
    });
    builder.addCase(
      fetchBackupExportByBackupExportId.fulfilled,
      (state, action) => {
        backupExportsAdapter.upsertOne(state, action.payload);
      }
    );
  },
});

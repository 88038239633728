import { AccountUpdateRequest, AuthUser, SignupRequest } from "../models";
import { baseApi } from "./base-api";

export const fetchCurrentUser = () => {
  return baseApi.get<AuthUser>("/auth/userinfo");
};

export const signup = (req: SignupRequest) => {
  return baseApi.post<AuthUser>("/auth/signup", req);
};

export const updateAccount = (req: AccountUpdateRequest) => {
  return baseApi.patch<AuthUser>("/account", req);
};

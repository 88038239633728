import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { selectAuthState } from "../lib/store";
import { ACCOUNT_SHOPS_ROUTE } from "../lib/routes";

export const navItems = [
  {
    title: "Salesforce Backup",
    iconClassName: "fe fe-calendar nav-icon",
    href: "/account/backup",
    access: "backup",
  },
  {
    title: "Data Cleaner",
    iconClassName: "fe fe-calendar nav-icon",
    href: "/account/cleaner",
    access: "data_cleaner",
  },
  {
    title: "Shop Builder",
    iconClassName: "mdi mdi-view-grid-plus-outline nav-icon",
    href: ACCOUNT_SHOPS_ROUTE,
    // access: "shops",
  },
];

export const AccountSidenav = () => {
  const router = useRouter();
  const { user } = useSelector(selectAuthState);

  const navItemClass = (path: string) => {
    let c = "nav-item";
    if (router.asPath.startsWith(path)) {
      c += " active";
    }
    return c;
  };

  const filteredNavItems = navItems.filter(
    (item) => !item.access || user.access[item.access]
  );

  return (
    <nav className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">
      <a
        className="d-xl-none d-lg-none d-md-none text-inherit fw-bold"
        href="#!"
      >
        Menu
      </a>

      <button
        className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary text-light"
        type="button"
        data-toggle="collapse"
        data-target="#sidenav"
        aria-controls="sidenav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="fe fe-menu"></span>
      </button>

      <div className="collapse navbar-collapse" id="sidenav">
        <div className="navbar-nav flex-column">
          <span className="navbar-header">Subscription</span>
          <ul className="list-unstyled ms-n2 mb-4">
            {!user.needs_to_signup && (
              <li className={navItemClass("/account/subscriptions")}>
                <Link
                  href="/account/subscriptions"
                  passHref
                  className="nav-link"
                >
                  <>
                    <i className="fe fe-calendar nav-icon"></i>My Subscriptions
                  </>
                </Link>
              </li>
            )}

            <li className={navItemClass("/account/billing")}>
              <Link href="/account/billing" passHref className="nav-link">
                <>
                  <i className="fe fe-credit-card nav-icon"></i>Billing Info
                </>
              </Link>
            </li>

            {/* 
            <li className={navItemClass("/account/payment")}>
              <Link href="/account/payment" passHref>
                <a className="nav-link">
                  <i className="fe fe-credit-card nav-icon"></i>Payment
                </a>
              </Link>
            </li> 
            
            <li className={navItemClass("/account/invoices")}>
              <Link href="/account/invoices" passHref>
                <a className="nav-link">
                  <i className="fe fe-clipboard nav-icon"></i>Invoices
                </a>
              </Link>
            </li> 
            */}
          </ul>
          {/* <li className={navItemClass("/account/backup/data")}>
              <Link href="/account/backup/data" passHref>
                <a className="nav-link">
                  <i className="fe fe-calendar nav-icon"></i>Data Browser
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/search")}>
              <Link href="/account/backup/search" passHref>
                <a className="nav-link">
                  <i className="fe fe-search nav-icon"></i>Search
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/schedules")}>
              <Link href="/account/backup/schedules" passHref>
                <a className="nav-link">
                  <i className="fe fe-calendar nav-icon"></i>Schedules
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/duplicates")}>
              <Link href="/account/backup/duplicates" passHref>
                <a className="nav-link">
                  <i className="fe fe-clipboard nav-icon"></i>Duplicates
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/exports")}>
              <Link href="/account/backup/exports" passHref>
                <a className="nav-link">
                  <i className="fe fe-clipboard nav-icon"></i>Exports
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/insights")}>
              <Link href="/account/backup/insights" passHref>
                <a className="nav-link">
                  <i className="fe fe-clipboard nav-icon"></i>Insights
                </a>
              </Link>
            </li> */}
          {/* <li className={navItemClass("/account/backup/alerts")}>
              <Link href="/account/backup/alerts" passHref>
                <a className="nav-link">
                  <i className="fe fe-clipboard nav-icon"></i>Alerts
                </a>
              </Link>
            </li> */}
          {filteredNavItems.length > 0 && (
            <>
              <span className="navbar-header">Apps</span>
              <ul className="list-unstyled ms-n2 mb-4">
                {filteredNavItems.map((item) => (
                  <li className={navItemClass(item.href)} key={item.href}>
                    <Link href={item.href} passHref className="nav-link">
                      <>
                        <i className={item.iconClassName}></i>
                        {item.title}
                      </>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          <span className="navbar-header">Account Settings</span>
          <ul className="list-unstyled ms-n2 mb-0">
            <li className="nav-item">
              <a className="nav-link" href="/api/auth/signout">
                <i className="fe fe-power nav-icon"></i>Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

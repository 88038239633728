import { Button, Modal } from "react-bootstrap";

interface PurchaseSuccessModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  body: string;
}

export const PurchaseSuccessModal = ({
  show,
  onClose,
  title,
  body,
}: PurchaseSuccessModalProps) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          <h1>{title}</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-0">{body}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

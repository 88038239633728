import { Testimonial } from "../lib/models";
import { TestimonialListItem } from "./testimonial-list-item";
import { CSlider } from "./c-slider";
import Link from "next/link";

export interface SectionTestimonialsProps {
  title?: string;
  subtitle?: string;
  testimonials: Testimonial[];
  slidesToShow?: number;
  isProductPage?: boolean;
}

export const SectionTestimonials = ({
  testimonials,
  title = "Feedback from our customers",
  subtitle,
  slidesToShow = 3,
  isProductPage,
}: SectionTestimonialsProps) => {
  const sliderSettings = {
    speed: 500,
    arrows: false,
    slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row mb-8 justify-content-center">
        <div className="col-lg-8 col-md-12 col-12 text-center">
          <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
            Testimonials
          </span>
          <h2 className="mb-2 display-4 fw-bold">{title}</h2>
          {subtitle && <p className="lead">{subtitle}</p>}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <CSlider settings={sliderSettings} controlsPosition="bottom">
            {testimonials.map((t) => (
              <div className="ps-2 pe-2" key={t.title}>
                {isProductPage ? (
                  <TestimonialListItem {...t} isProductPage={isProductPage} />
                ) : (
                  <Link href={`/products/${t.app_key}`}>
                    <TestimonialListItem {...t} />
                  </Link>
                )}
              </div>
            ))}
          </CSlider>
        </div>
      </div>
    </>
  );
};

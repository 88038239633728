import Link from "next/link";
import { SalesforceLoginButton } from "./salesforce-login-button";

interface SalesforceLoginProps {
  redirectUrl?: string;
}

export const SalesforceLogin = ({ redirectUrl }: SalesforceLoginProps) => {
  return (
    <div className="text-center">
      <SalesforceLoginButton redirectUrl={redirectUrl} />
      <p className="mb-0 mt-4">
        <small>
          Your login is only used to create your CloudAnswers profile.
        </small>
      </p>
      <p>
        <small>No applications will be installed on your Salesforce org.</small>
      </p>
      <small>
        By signing in, you agree to our{" "}
        <Link href="/subscription-agreement">Terms of use</Link> and{" "}
        <Link href="/privacy-policy">Privacy Policy</Link>
      </small>
    </div>
  );
};

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { CASubscription } from "../../models";
import { fetchAllSubscriptions, fetchSubscriptionByProductId } from "./actions";

export const subscriptionsAdapter = createEntityAdapter<CASubscription>();

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: subscriptionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSubscriptions.fulfilled, (state, action) => {
      subscriptionsAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchSubscriptionByProductId.fulfilled, (state, action) => {
      subscriptionsAdapter.upsertOne(state, action.payload);
    });
  },
});

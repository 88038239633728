import { useSelector } from "react-redux";
import { selectAuthState } from "../lib/store";

export const NonBillingUserMessage = () => {
  const { user } = useSelector(selectAuthState);
  const billingUsers = user?.billing_users;

  if (!billingUsers) return null;
  const users = billingUsers.join(", ");
  return (
    <>{`Uh Oh. You are not an authorized billing contact. Please contact ${users} to update your billing information or have them contact us to add you as an authorized contact.`}</>
  );
};

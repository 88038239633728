import { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Link from "next/link";

import { Service, ServiceCategory } from "../lib/models";
import { ServiceListItem } from "./service-list-item";

export interface ServiceListTabsProps {
  services: { category: ServiceCategory; items: Service[] }[];
}

export const ServiceListTabs = ({ services }: ServiceListTabsProps) => {
  const tabHeaders = services.map((p) => p.category.title);
  const [activeTab, setActiveTab] = useState(tabHeaders[0]);

  return (
    <Tab.Container
      activeKey={activeTab}
      defaultActiveKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
    >
      {/* Tab navigation */}
      <Nav className="nav-lb-tab mb-6" defaultActiveKey={activeTab}>
        {tabHeaders.map((key) => (
          <Nav.Item key={key}>
            <Nav.Link eventKey={key}>{key}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {/* Tab content */}
      <Tab.Content>
        {services.map((p) => (
          <Tab.Pane
            key={p.category.title}
            eventKey={p.category.title}
            className="tab-pane fade position-relative"
          >
            <>
              <div style={{ position: "absolute", top: "-35px", right: 0 }}>
                <Link href={p.category.slug} passHref className="btn btn-link">
                  <>
                    Show all{" "}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </>
                </Link>
              </div>
              <div className="pt-3">
                <div className="row">
                  {p.items.map((pp, i) => (
                    <div key={pp.title + i} className="col-lg-3 mb-4">
                      <ServiceListItem {...pp} />
                    </div>
                  ))}
                </div>
              </div>
            </>
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

import { Action } from "redux";

export interface LoadingState {
  [requestName: string]: boolean;
}

const initialState: LoadingState = {};

export const loadingReducer = (
  state: LoadingState = initialState,
  action: Action
) => {
  const { type } = action;
  const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

  // not a *pending / *fulfilled /  *rejected actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "pending",
  };
};

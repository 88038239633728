import { createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "../../api/subscriptions";

export const fetchAllSubscriptions = createAsyncThunk(
  "subscriptions/FETCH_ALL",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubscriptionByProductId = createAsyncThunk(
  "subscriptions/FETCH_BY_PRODUCT_ID",
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await api.fetchByProductId(productId);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

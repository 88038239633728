import { ChangeEventHandler, FC, FormEventHandler, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { AccountUpdateRequest } from "../../lib/models";
import {
  selectAuthState,
  selectLoading,
  updateAccount,
  useAppDispatch,
} from "../../lib/store";
import { Loader } from "../loader";

interface FormValues extends AccountUpdateRequest {}

export interface UpdateAccountModalProps {
  onClose: () => void;
}

export const UpdateAccountModal: FC<UpdateAccountModalProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useSelector(selectAuthState);
  const isLoading = useSelector(selectLoading([updateAccount]));
  const [values, setValues] = useState<FormValues>({
    company_name: user.company_name,
  });

  const handleChange =
    (key: keyof FormValues): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setValues({ ...values, [key]: e.target.value });
    };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updateAccount(values)).unwrap();
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const renderContent = () => (
    <Form.Group className="mb-4">
      <Form.Label>Company name*</Form.Label>
      <Form.Control
        type="text"
        name="company_name"
        value={values.company_name}
        onChange={handleChange("company_name")}
        required
      />
    </Form.Group>
  );

  const renderLoading = () => {
    return (
      <div className="text-center">
        <Loader />
        <p>Processing...</p>
      </div>
    );
  };

  return (
    <Modal centered show onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>Change your company name</Modal.Title>
      </Modal.Header>
      <Form name="signup" onSubmit={handleSubmit}>
        <Modal.Body>{isLoading ? renderLoading() : renderContent()}</Modal.Body>
        {!isLoading && (
          <Modal.Footer>
            <Button variant="text" onClick={onClose} size="sm">
              Cancel
            </Button>
            <Button variant="primary" type="submit" size="sm">
              Change
            </Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

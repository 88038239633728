import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { schedulesAdapter } from "./schedules";
import { backupExportsAdapter } from "./backup-exports";

import { AppState } from "./store";
import { subscriptionsAdapter } from "./subscriptions";

export const selectAuthState = (state: AppState) => state.auth;
export const selectLoadingState = (state: AppState) => state.loading;
export const selectSubscriptionsState = (state: AppState) =>
  state.subscriptions;
export const selectSchedulesState = (state: AppState) => state.schedules;
export const selectBackupExportsState = (state: AppState) =>
  state.backupExports;

// Subscriptions
export const subscriptionSelectors = subscriptionsAdapter.getSelectors(
  selectSubscriptionsState
);
export const selectSubscriptionByProductId = (productId: string) => {
  return createSelector(subscriptionSelectors.selectAll, (subs) =>
    subs.find((s) => s.product.id === productId)
  );
};

// Schedules
export const scheduleSelectors =
  schedulesAdapter.getSelectors(selectSchedulesState);
export const selectScheduleByScheduleId = (scheduleId: string) => {
  return createSelector(scheduleSelectors.selectAll, (schedules) =>
    schedules.find((s) => s.id === scheduleId)
  );
};

// Exports
export const backupExportSelectors = backupExportsAdapter.getSelectors(
  selectBackupExportsState
);
export const selectBackupExportByBackupExportId = (exportId: string) => {
  return createSelector(backupExportSelectors.selectAll, (backupExports) =>
    backupExports.find((be) => be.id === exportId)
  );
};

// Loading
const createAsyncThunkUnTyped = () => createAsyncThunk<any, any>("", () => {});
export const selectLoading =
  (actions: ReturnType<typeof createAsyncThunkUnTyped>[]) =>
  (state: AppState) => {
    return actions.some((action) => {
      const type = action.pending.type;
      const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

      if (!matches) {
        return false;
      }

      const [, requestName] = matches;
      return state.loading[requestName];
    });
  };

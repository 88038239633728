interface ReviewStarsProps {
  score: number;
}

export const ReviewStars = ({ score }: ReviewStarsProps) => {
  return (
    <>
      {[0, 1, 2, 3, 4].map((val) => (
        <i
          key={val}
          className={
            (Math.ceil(score) > val ? "text-warning" : "text-light") +
            " mdi mdi-star"
          }
        ></i>
      ))}
    </>
  );
};

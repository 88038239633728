import { useEffect } from "react";

export interface EmbedHubspotMeetingProps {
  username: string;
}

export const EmbedHubspotMeeting = ({ username }: EmbedHubspotMeetingProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="meetings-iframe-container"
      data-src={`https://meetings.hubspot.com/${username}?embed=true`}
    ></div>
  );
};

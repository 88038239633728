import Link from "next/link";
import { useRouter } from "next/router";
import { Nav, NavDropdown } from "react-bootstrap";
import { MenuItem } from "../lib/models";

interface NavItemProps {
  item: MenuItem;
  isMobile?: boolean;
  onClick?: () => void;
}

export const NavItem = ({ item, isMobile, onClick }: NavItemProps) => {
  const { asPath } = useRouter();
  const { slug, label, children } = item;
  const hasChildren = children && children.length > 0;

  const dropdownItemClass = `${isMobile ? "" : "dropdown-item"} nav-link`;

  const renderRegularItem = () => (
    <Nav.Item className="nav-link">
      <NavDropdown.Item
        href={slug}
        as={Link}
        prefetch={false}
        passHref
        active={asPath === slug}
        className="nav-link"
        onClick={onClick}
      >
        {label}
      </NavDropdown.Item>
    </Nav.Item>
  );

  const renderDropdownItem = () => (
    <NavDropdown
      title={label}
      show={isMobile ? undefined : true}
      active={asPath.startsWith(slug)}
      className={dropdownItemClass}
    >
      {children.map(renderDropdownChild)}
    </NavDropdown>
  );

  const renderDropdownChild = (child: MenuItem, i: number) => (
    <NavDropdown.Item
      as={Link}
      key={child.label + i}
      href={child.slug}
      prefetch={false}
      passHref
      className={asPath === child.slug ? "active" : ""}
      onClick={onClick}
    >
      {child.label}
    </NavDropdown.Item>
  );
  return hasChildren ? renderDropdownItem() : renderRegularItem();
};

import { Person } from "../lib/models";
import { PersonList } from "./person-list";

interface SectionExpertsProps {
  experts: Person[];
}

export const SectionExperts = ({ experts }: SectionExpertsProps) => {
  return (
    <>
      <div className="row mb-8 justify-content-center">
        <div className="col-lg-8 col-md-12 col-12 text-center">
          <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
            Team
          </span>
          <h2 className="mb-2 display-4 fw-bold">Together we achieve more.</h2>
          <p className="lead">
            At CloudAnswers, all minds are tools. We sharpen our tools by
            working as one.
          </p>
        </div>
      </div>
      <PersonList people={experts} />
    </>
  );
};

import { MDXRemote } from "next-mdx-remote";
import { Accordion } from "react-bootstrap";
import { mdxComponents } from "../lib/mdx";
import { Step } from "../lib/models";

interface ServiceStepProps {
  step: Step;
}

export const ServiceStep = ({ step }: ServiceStepProps) => {
  return (
    <li className="list-group-item">
      {/* 
      FIXME: Accordion.Toggle is not working
      <Accordion.Toggle
        as={"a"}
        role="button"
        eventKey={`${step.title}`}
        className="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none"
      >
        <div className="me-auto">{step.title}</div>

        <span className="chevron-arrow ms-4">
          <i className="fe fe-chevron-down fs-4"></i>
        </span>
      </Accordion.Toggle> */}

      <Accordion.Collapse eventKey={`${step.title}`}>
        <div className="pt-3 pb-2">
          <MDXRemote {...step.contentMdx} components={mdxComponents} />
        </div>
      </Accordion.Collapse>
    </li>
  );
};

export const submitIntercomForm = async (formName, formValues) => {
  const res = await fetch("/api/sendToIntercom", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ formName, ...formValues }),
  });
  if (res.status === 200) {
    console.log("Form submitted successfully!");
  } else {
    console.error("Form submission failed!", res);
    throw res;
  }
};

import { useContext, useState } from "react";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import { Container, Nav, Navbar } from "react-bootstrap";
import { GlobalPropsContext } from "../pages/_app";
import { NavItem } from "./nav-item";
import { CImage } from "./c-image";
import { TopnavLogin } from "./topnav-login";
import useMounted from "../hooks/useMounted";

export const Topnav = () => {
  const { topnavMenu, config } = useContext(GlobalPropsContext);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const hasMounted = useMounted();
  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isMobile = !isDesktop && !isLaptop;

  const renderMenu = () => (
    <Nav>
      {topnavMenu.map((m, i) => (
        <NavItem
          key={m.slug + i}
          item={m}
          isMobile={hasMounted && isMobile}
          onClick={() => setExpandedMenu(false)}
        />
      ))}
    </Nav>
  );

  return (
    <Navbar
      expand="lg"
      className="navbar p-2 navbar-default py-2"
      expanded={expandedMenu}
      onToggle={(collapsed) => setExpandedMenu(collapsed)}
    >
      <Container fluid className="px-0 ps-2">
        <Link href="/" passHref legacyBehavior>
          <Navbar.Brand href="/" className="navbar-nav-brand">
            <CImage
              src={config.logo}
              width={200}
              height={30}
              alt={`${config.company_name} logo`}
              className="img-fluid navbar-nav-brand-img"
              objectFit="contain"
            />
          </Navbar.Brand>
        </Link>
        {hasMounted && isMobile && (
          <div className="navbar-nav navbar-right-wrap ms-auto nav-top-wrap d-flex">
            <TopnavLogin />
          </div>
        )}
        <div>
          <Navbar.Toggle aria-controls="navbar-default">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="navbar-default">{renderMenu()}</Navbar.Collapse>
        {hasMounted && !isMobile && (
          <div className="ms-auto d-flex align-items-center">
            <span>
              <TopnavLogin />
            </span>
          </div>
        )}
      </Container>
      {/* 
        Tricky. This is a hack to make loaded css styles for dropdown items.
      */}
      <div className="dropdown-item position-absolute top-0"></div>
    </Navbar>
  );
};

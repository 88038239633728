import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const YoutubeVideoModal = ({
  youtubeId,
  isOpen,
  onClose,
}: {
  youtubeId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      aria-labelledby="youtube-modal"
      centered
    >
      <Modal.Body>
        <iframe
          className="youtube-iframe"
          src={`//www.youtube.com/embed/${youtubeId}?autoplay=1&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <Button className="close-button" variant="outline" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} size="2x" style={{ color: "#fff" }} />
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default YoutubeVideoModal;

import Link from "next/link";
import { Person } from "../lib/models";
import { PersonListItem } from "./person-list-item";

interface PersonListProps {
  people: Person[];
}

export const PersonList = ({ people }: PersonListProps) => {
  return (
    <div className="row">
      {people.map((e) => (
        <div key={e.title} className="col-xl-3 col-lg-4 col-md-6 col-12 mb-4">
          {e.render_single_page ? (
            <Link href={e.slug} passHref>
              <PersonListItem {...e} />
            </Link>
          ) : (
            <PersonListItem {...e} />
          )}
        </div>
      ))}
    </div>
  );
};

import { CImage } from "./c-image";

interface SalesforceLoginButton {
  redirectUrl?: string;
}

export const SalesforceLoginButton = ({
  redirectUrl,
}: SalesforceLoginButton) => {
  let url = "/api/auth/salesforce/start";
  if (redirectUrl) {
    url += `?state=${redirectUrl}`;
  }

  return (
    <>
      <div className="my-3">
        <CImage
          width={160}
          height={80}
          objectFit="contain"
          src="/assets/images/salesforce-logo-blue.png"
          alt="salesforce logo"
          className="d-inline-block"
        />
      </div>
      <a href={url} className="btn btn-primary">
        <div className="d-flex align-items-center">
          <div>
            Sign in with Salesforce <br /> (via developer or production org)
          </div>
        </div>
      </a>
    </>
  );
};

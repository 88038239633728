import { Stripe } from "@stripe/stripe-js";
import { CASubscription } from "../models";
import { baseApi } from "./base-api";

export const getSubscriptionSession = (priceId: string) => {
  return baseApi
    .post("/checkout-session", {
      priceId,
    })
    .then((d) => d.data.sessionId);
};

export const fetchAll = () => {
  return baseApi.get<CASubscription[]>("/account/subscriptions");
};

export const fetchByProductId = (productId: string) => {
  return baseApi.get<CASubscription>(
    `/account/subscriptions/by_product/${productId}`
  );
};

export const checkoutSubscription = async (stripe: Stripe, priceId: string) => {
  try {
    const sessionId = await getSubscriptionSession(priceId);
    stripe.redirectToCheckout({ sessionId });
  } catch (err) {
    throw err;
  }
};

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { fetchCurrentUser, selectAuthState, selectLoading } from "../lib/store";
import { AccountSidenav } from "./account-sidenav";
import { Loader } from "./loader";
import { UpdateAccountModal } from "./update-account-modal";

interface AccountLayoutProps {
  children: React.ReactNode;
}

export const AccountLayout: FunctionComponent<AccountLayoutProps> = ({
  children,
}) => {
  const router = useRouter();
  const isLoading = useSelector(selectLoading([fetchCurrentUser]));
  const [updateAccountIsOpen, setUpdateAccountIsOpen] = useState(false);
  const { user, isAuthenticated, initialized } = useSelector(selectAuthState);

  useEffect(() => {
    if (initialized && !isAuthenticated && !isLoading) {
      const url = `/login?redirect_url=${encodeURIComponent(router.asPath)}`;
      router.replace(url);
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <Loader containerMinHeight="60vh" />;
  }

  return (
    <div className="pt-5 pb-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div
              className="pt-16 rounded-top"
              style={{
                background: "url(/assets/images/billing-banner.jpeg) no-repeat",
                backgroundSize: "auto auto",
              }}
            ></div>
            <div className="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom shadow-sm">
              <div className="d-flex align-items-center">
                <div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                  <img
                    height={50}
                    width={50}
                    src="/api/user/photo"
                    className="avatar-xl rounded-circle border-width-4 border-white"
                    alt=""
                  />
                </div>
                <div className="lh-1">
                  <h2 className="mb-0">
                    {user?.name} ({user?.email})
                  </h2>
                  <p className="mb-0 d-flex align-items-center">
                    {user?.company_name}{" "}
                    {user.is_billing_user && (
                      <FontAwesomeIcon
                        className="ms-1"
                        style={{ cursor: "pointer" }}
                        icon={faPen}
                        size="1x"
                        width={15}
                        onClick={() => setUpdateAccountIsOpen(true)}
                      />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-3 col-md-4 col-12">
            <AccountSidenav />
          </div>
          <div className="col-lg-9 col-md-8 col-12">{children}</div>
        </div>
      </div>
      {updateAccountIsOpen && (
        <UpdateAccountModal onClose={() => setUpdateAccountIsOpen(false)} />
      )}
    </div>
  );
};

import { ReactNode } from "react";

export interface BlockquoteProps {
  children: ReactNode;
}

export const Blockquote = ({ children }: BlockquoteProps) => {
  return (
    <blockquote className="blockquote text-center">
      <p className="text-primary fst-italic fw-semi-bold lh-lg h1 px-2 px-lg-14">
        {children}
      </p>
    </blockquote>
  );
};

import { Client } from "../lib/models";
import { CImage } from "./c-image";

export interface SectionClientsProps {
  clients: Client[];
}

export const SectionClients = ({ clients }: SectionClientsProps) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12 col-12 text-center">
          <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
            Clients & Partners
          </span>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        {clients.map((c) => (
          <div key={c.title} className="col-lg-3 col-md-4 col-6">
            <div className="mb-4 text-center align-middle px-3 px-lg-5">
              <CImage
                src={c.image}
                objectFit="contain"
                width={250}
                height={200}
                alt={c.title + " logo"}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

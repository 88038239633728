import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/backup-exports";
import { BackupExport } from "../../models/backup-export";

const backupExportsJsonList: BackupExport[] = [
  {
    id: "abc",
    name: "Weekly Run",
    records: 102,
    createdAt: 12228478473,
    expiresIn: 360000,
    size: 1893000,
  },
];

export const fetchAllBackupExports = createAsyncThunk(
  "backupExports/FETCH_BACKUP_ALL",
  async (_, { rejectWithValue }) => {
    try {
      // todo
      // const response = await api.fetchAll();
      const response = backupExportsJsonList;
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBackupExportByBackupExportId = createAsyncThunk(
  "backupExports/FETCH_BY_BACKUP_EXPORT_ID",
  async (exportId: string, { rejectWithValue }) => {
    try {
      // todo
      // const response = await api.fetchByExportId(exportId);
      const response = backupExportsJsonList.find(
        (backupExport) => backupExport.id === exportId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { MDXRemote } from "next-mdx-remote";
import { Card } from "react-bootstrap";
import { mdxComponents } from "../lib/mdx";

interface DocumentPageProps {
  title: string;
  date: string;
  contentMdx: any;
}

export const DocumentPage = ({
  title,
  date,
  contentMdx,
}: DocumentPageProps) => {
  return (
    <>
      {/* Hero */}
      <div className="py-lg-13 py-8 bg-primary">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
              <div className="text-center mb-6 px-md-8">
                <h1 className="text-white display-3 fw-bold">{title}</h1>
                <p className="text-white mt-4">Last updated: {date}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="mt-n8 pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <Card>
                <Card.Body>
                  <MDXRemote {...contentMdx} components={mdxComponents} />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
